import { Integrations } from './types';

export const addNewrelicAction = (actionName: string, value: Record<string, unknown>) => {
  if (typeof window !== 'undefined') {
    const valueWithPrefixes: Record<string, unknown> = {};

    for (const key in value) {
      valueWithPrefixes[`${actionName}_${key}`] = value[key];
    }

    window.newrelic?.addPageAction(actionName, valueWithPrefixes);
  }
};

export const initSegment = () => {
  const global = window;
  // Create a queue, but don't obliterate an existing one!
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics = (window.analytics = (window as any).analytics || []);

  // If the real analytics.js is already on the page return.
  if (analytics?.initialize) return;

  // If the snippet was invoked already show an error.
  if (analytics?.invoked) {
    if (global.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data.
  // stored as the first argument, so we can replay the data.
  analytics.factory = function(method: unknown) {
    return function() {
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      if (analytics.indexOf(args) === -1) {
        analytics.push(args);
      }
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (var i = 0; i < analytics.methods.length; i++) {
    var key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function(key: string, options: unknown) {
    global.addEventListener('load', function() {
      // Create an async script element based on your key.
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = false;
      script.src = 'https://p.withjoy.com/segcdn/analytics.js/v1/' + key + '/analytics.min.js';
      // Insert our script next to the first script element.
      var first = document.getElementsByTagName('script')[0];
      first.parentNode?.insertBefore(script, first);
      analytics._loadOptions = options;
    });
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.13.2';

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!

  var debuggerKey = '2xZJ98aly8fmfFDB9msDQlXsyVGVpUby';
  var prodKey = 'oHWqP4BrVeBDl1FxCTC45FfLZ0qnySFw';

  var analyticsConfig = {
    integrations: {
      [Integrations.SegmentIO]: {
        apiHost: 'p.withjoy.com/segapi/v1',
      },
      [Integrations.Intercom]: false,
      [Integrations.Chameleon]: false,
    },
  };

  if (global.location.hostname === 'withjoy.com') {
    // Prod
    analytics.load(prodKey, analyticsConfig);
  } else {
    // Running locally or Test environments like blue.withjoy.com, rehearsal.withjoy.com, etc
    analytics.load(debuggerKey, analyticsConfig);
  }

  if (global) {
    global.analytics = analytics;
  }
};

export function storeImpactRadiusInfo() {
  if (typeof window !== 'undefined') {
    var global = window;
    var queryString = global.location.search || '';
    var landingPage = global.location.pathname.replace(/\\|\//g, '');
    var matches = queryString.match(/[?&]irclickid=([^&]+)/i);
    var irClickId = matches && matches[1];
    if (irClickId) {
      var document = global.document;
      var existingCookies = document.cookie || '';
      if (existingCookies.search(/(^|;\s)joy_irclickid=/i) === -1) {
        var COOKIE_TTL_DAYS = 30;
        var expiration = new Date(new Date().getTime() + COOKIE_TTL_DAYS * 1000 * 60 * 60 * 24);
        document.cookie = 'joy_irclickid=' + irClickId + ';expires=' + expiration.toUTCString() + ';path=/;';
        document.cookie = 'joy_irtext1=' + landingPage + ';expires=' + expiration.toUTCString();
      }
    }
  }
}
